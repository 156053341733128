// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("D:\\a\\1\\s\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-post-js": () => import("D:\\a\\1\\s\\src\\templates\\blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-blog-js": () => import("D:\\a\\1\\s\\src\\pages\\blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-conductor-js": () => import("D:\\a\\1\\s\\src\\pages\\conductor.js" /* webpackChunkName: "component---src-pages-conductor-js" */),
  "component---src-pages-index-js": () => import("D:\\a\\1\\s\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jarvis-js": () => import("D:\\a\\1\\s\\src\\pages\\jarvis.js" /* webpackChunkName: "component---src-pages-jarvis-js" */),
  "component---src-pages-ourbots-js": () => import("D:\\a\\1\\s\\src\\pages\\ourbots.js" /* webpackChunkName: "component---src-pages-ourbots-js" */),
  "component---src-pages-ourbots-arnold-js": () => import("D:\\a\\1\\s\\src\\pages\\ourbots\\Arnold.js" /* webpackChunkName: "component---src-pages-ourbots-arnold-js" */),
  "component---src-pages-ourbots-jarvis-js": () => import("D:\\a\\1\\s\\src\\pages\\ourbots\\Jarvis.js" /* webpackChunkName: "component---src-pages-ourbots-jarvis-js" */),
  "component---src-pages-ourbots-lisa-js": () => import("D:\\a\\1\\s\\src\\pages\\ourbots\\Lisa.js" /* webpackChunkName: "component---src-pages-ourbots-lisa-js" */),
  "component---src-pages-ourbots-our-team-bot-js": () => import("D:\\a\\1\\s\\src\\pages\\ourbots\\OurTeamBot.js" /* webpackChunkName: "component---src-pages-ourbots-our-team-bot-js" */),
  "component---src-pages-privacy-js": () => import("D:\\a\\1\\s\\src\\pages\\privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-sundial-js": () => import("D:\\a\\1\\s\\src\\pages\\sundial.js" /* webpackChunkName: "component---src-pages-sundial-js" */),
  "component---src-pages-tte-js": () => import("D:\\a\\1\\s\\src\\pages\\tte.js" /* webpackChunkName: "component---src-pages-tte-js" */)
}

