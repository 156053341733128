module.exports = [{
      plugin: require('D:/a/1/s/node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-153687473-1"},"environments":["production","development"]},
    },{
      plugin: require('D:/a/1/s/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"backgroundColor":"transparent"},
    },{
      plugin: require('D:/a/1/s/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('D:/a/1/s/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Chatforge","short_name":"Chatforge","start_url":"/","background_color":"#ffffff","theme_color":"`#663399","display":"minimal-ui","icon":"content/assets/silogo.png"},
    },{
      plugin: require('D:/a/1/s/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('D:/a/1/s/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
