import "./src/styles/chatforge.css"
import "typeface-montserrat"
import "typeface-merriweather"
import "./src/styles/meetTheTeam.css"
import "./src/styles/heroText.css"
import "./src/styles/examples.css"
import "./src/styles/blog.css"
import "./src/styles/botcard.css"
import "./src/styles/botprofile.css"
import "./src/styles/infoPage.css"

import "./src/components/jarvis-main/jarvis-main-style.css"
import "./src/components/jarvis-shift-managment/jarvis-shift-managment-style.css"
import "./src/components/jarvis-empowering-employees/jarvis-empowering-employees-style.css"
import "./src/components/jarvis-digitising-internal-com/jarvis-digitising-internal-com-style.css"
import "./src/components/jarvis-connecting-internal-systems/jarvis-connecting-internal-systems-style.css"